<template>
  <v-app>
    <v-main>
      <v-layout v-if="loaded">
        <slot />
      </v-layout>
    </v-main>
  </v-app>
</template>

<script setup>
const loaded = ref(false);

useHead({
  meta: [
    {
      name: "googlebot",
      content: "noindex",
    },
  ],
});

onMounted(() => {
  setTimeout(() => {
    loaded.value = true;
  }, 300);
});
</script>

<style scoped>
.v-main {
  margin-top: 0px !important;
  background-color: #fff !important;
}
</style>
